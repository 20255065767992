import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { registerSW } from 'virtual:pwa-register'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { SnackbarContextProvider } from './contexts/SnackbarContext'
import Router from './router/Router'
import Loading from './components/Loading'
import { QueryClient } from '@tanstack/react-query'
import { createIDBPersister } from './query/IDBPersister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { AuthContextProvider } from './contexts/AuthContext'

const theme = createTheme()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const persister = createIDBPersister('GENRO')
registerSW()

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <Suspense fallback={<Loading open={true} />}>
          <AuthContextProvider>
            <SnackbarContextProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </SnackbarContextProvider>
          </AuthContextProvider>
        </Suspense>
      </PersistQueryClientProvider>
    </ThemeProvider>
  )
}

export default App
