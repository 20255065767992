import React from 'react'
import { STORAGE_KEY_USER } from '../constants'
import { User } from '../types'

export interface AuthContextType {
  user: User | null
  login: (user: User, callback: () => void) => void
  logout: (callback: () => void) => void
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AuthContext = React.createContext<AuthContextType>({} as AuthContextType)

interface Props {
  children: React.ReactNode
}

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const item = localStorage.getItem(STORAGE_KEY_USER)
  const stored = item != null ? JSON.parse(item) : null
  const [user, setUser] = React.useState<User | null>(stored)

  const login = (newUser: User, callback: () => void): void => {
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(newUser))
    setUser(newUser)
    callback()
  }

  const logout = (callback: () => void): void => {
    localStorage.removeItem(STORAGE_KEY_USER)
    setUser(null)
    callback()
  }

  const newContext: AuthContextType = { user, login, logout }

  return <AuthContext.Provider value={newContext}>{children}</AuthContext.Provider>
}

export const useAuthContext = (): AuthContextType => {
  return React.useContext<AuthContextType>(AuthContext)
}
