export const MSG_LOAD_SUCCESS = '製品データを更新しました。'
export const MSG_NO_HISTORY = '履歴がありません。'
export const MSG_LOGIN_FAILURE = 'ログインできませんでした。'
export const MSG_CAMERA_LAUNCH_FAILURE =
  'カメラを起動できませんでした。アプリを再起動してください。'

export const MSG_LOAD_FAILURE =
  '製品データを更新できませんでした。インターネットに接続されていることを確認してください。'
export const MSG_FETCH_FAILURE =
  '製品データを取得できませんでした。インターネットに接続されていることを確認してください。'

export const MSG_UNKNOWN_PRODUCT_CODE = '製品情報が見つかりません。'

export const MSG_INVENTORY_ADD_SUCCESS = '棚卸データを追加しました。'
export const MSG_INVENTORY_ADD_FAILURE =
  '棚卸データを追加できませんでした。インターネットに接続されていることを確認してください。'

export const MSG_INVENTORY_DELETE_SUCCESS = '棚卸データを削除しました。'
export const MSG_INVENTORY_DELETE_FAILURE =
  '棚卸データを削除できませんでした。インターネットに接続されていることを確認してください。'

export const MSG_SELECTION_ADD_SUCCESS = '製品を追加しました。'
export const MSG_SELECTION_ADD_FAILURE =
  '製品を追加できませんでした。インターネットに接続されていることを確認してください。'

export const MSG_SELECTION_TRUNCATE_SUCCESS = 'セレクト内容を全削除しました。'
export const MSG_SELECTION_TRUNCATE_FAILURE =
  'セレクト内容を全削除できませんでした。インターネットに接続されていることを確認してください。'

export const MSG_SELECTION_DELETE_SUCCESS = 'セレクト内容を削除しました。'
export const MSG_SELECTION_DELETE_FAILURE =
  'セレクト内容を削除できませんでした。インターネットに接続されていることを確認してください。'

export const MSG_SELECTION_UPLOAD_SUCCESS = 'セレクト内容をアップロードしました。'
export const MSG_SELECTION_UPLOAD_FAILURE =
  'セレクト内容をアップロードできませんでした。インターネットに接続されていることを確認してください。'

export const MSG_SELECTION_UPLOAD_CONFIRM = 'セレクト内容をアップロードします。よろしいですか？'
export const MSG_SELECTION_TRUNCATE_CONFIRM = 'セレクト内容をすべて削除します。よろしいですか？'
export const MSG_SELECTION_DELETE_CONFIRM = 'セレクト内容を削除します。よろしいですか？'

export const MSG_SHIPMENT_UNKNOWN_REPORT_NO = '伝票番号が見つかりません。'
export const MSG_SHIPMENT_CONFIRMED_REPORT_NO = '伝票番号は確認済です。'
export const MSG_SHIPMENT_CONFIRM_SUCCESS = 'セレクト内容をアップロードしました。'
export const MSG_SHIPMENT_CONFIRM_FAILURE =
  '伝票を確認済に更新できませんでした。インターネットに接続されていることを確認してください。'

export const MSG_SCAN_EAN_13 = 'JANコードをスキャン'
export const MSG_SCAN_EAN_8 = 'バーコードをスキャン'

export const STORAGE_KEY_USER = 'user'
export const STORAGE_KEY_PRODUCTS = 'products'
export const STORAGE_KEY_SELECTION = 'selection'
export const DATA_EXPIRED_SEC = 86400
