import axios, { AxiosInstance } from 'axios'
import { STORAGE_KEY_USER } from '../constants'

const baseURL = import.meta.env.VITE_API_BASE
const timeout = parseInt(import.meta.env.VITE_API_TIMEOUT)
const headers = {
  'Content-type': 'application/json',
}

const loadToken = (): string => {
  const item = localStorage.getItem(STORAGE_KEY_USER)
  if (item != null) {
    const user = JSON.parse(item)
    return user.token
  }
  return ''
}

const buildHeaders = (useToken: boolean): object => {
  if (useToken) {
    const token = loadToken()
    const authorization = {
      Authorization: `Bearer ${token}`,
    }
    return { ...headers, ...authorization }
  }
  return headers
}

export const createClient = (useToken: boolean = true): AxiosInstance => {
  const headers = buildHeaders(useToken)
  return axios.create({ baseURL, headers, timeout })
}
