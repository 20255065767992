import React, { lazy, useMemo } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { AuthGuard } from './AuthGuard'
import Layout from '../pages/Layout'
import LoginPage from '../pages/LoginPage'
import Page404 from '../pages/Page404'

const IndexPage = lazy(async () => await import('../pages/IndexPage'))
const InformationIndexPage = lazy(async () => await import('../pages/Information/IndexPage'))
const ProductCameraPage = lazy(async () => await import('../pages/Product/CameraPage'))
const ProductHistoryPage = lazy(async () => await import('../pages/Product/HistoryPage'))
const ProductInventoryPage = lazy(async () => await import('../pages/Product/InventoryPage'))
const ProductIndexPage = lazy(async () => await import('../pages/Product/IndexPage'))
const SelectionCameraPage = lazy(async () => await import('../pages/Selection/CameraPage'))
const SelectionIndexPage = lazy(async () => await import('../pages/Selection/IndexPage'))
const ShipmentIndexPage = lazy(async () => await import('../pages/Shipment/IndexPage'))
const ShipmentCameraPage = lazy(async () => await import('../pages/Shipment/CameraPage'))

const Router = (): React.ReactElement => {
  const navigate = useNavigate()
  React.useEffect(() => {}, [navigate])

  const authRoutes = useMemo(() => {
    return [
      { path: '/index', component: <IndexPage /> },
      { path: '/product/camera', component: <ProductCameraPage /> },
      { path: '/product/index', component: <ProductIndexPage /> },
      { path: '/product/history', component: <ProductHistoryPage /> },
      { path: '/product/inventory', component: <ProductInventoryPage /> },
      { path: '/selection/index', component: <SelectionIndexPage /> },
      { path: '/selection/camera', component: <SelectionCameraPage /> },
      { path: '/shipment/index', component: <ShipmentIndexPage /> },
      { path: '/shipment/camera', component: <ShipmentCameraPage /> },
      { path: '/information/index', component: <InformationIndexPage /> },
    ]
  }, [])

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<AuthGuard component={<IndexPage />} redirect="/login" />} />
        {authRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<AuthGuard component={route.component} redirect="/login" />}
            />
          )
        })}
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

export default Router
