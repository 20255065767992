import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { MSG_LOGIN_FAILURE } from '../constants'
import { User } from '../types'
import { useSnackbar } from '../contexts/SnackbarContext'
import { useAuthContext } from '../contexts/AuthContext'
import { useLoginMutation } from '../query/useLoginMutation'

const LoginPage: React.FC = () => {
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()
  const { mutate } = useLoginMutation()
  const { login } = useAuthContext()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    mutate(
      {
        email: data.get('email') as string,
        password: data.get('password') as string,
      },
      {
        onSuccess: (user: User) => {
          login(user, () => {})
          navigate('/index', { replace: true })
        },
        onError: () => {
          showSnackbar(MSG_LOGIN_FAILURE, 'error')
        },
      },
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          ログイン
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            ログイン
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default LoginPage
