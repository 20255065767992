import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material'
import { Main } from './Layout/Main'
import HomeIcon from '@mui/icons-material/Home'
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen'
import SearchIcon from '@mui/icons-material/Search'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuthContext } from '../contexts/AuthContext'
import BottomMenu from './Layout/BottomMenu'
import AppBar from './Layout/AppBar'

const Layout: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { logout } = useAuthContext()

  const [open, setOpen] = React.useState(false)
  const toggleDrawer = (): void => {
    setOpen(!open)
  }

  const onClickTop = (event: React.MouseEvent<HTMLInputElement>): void => {
    setOpen(false)
    navigate('/index')
  }
  const onClickProduct = (event: React.MouseEvent<HTMLInputElement>): void => {
    setOpen(false)
    navigate('/product/camera')
  }
  const onClickSelection = (event: React.MouseEvent<HTMLInputElement>): void => {
    setOpen(false)
    navigate('/selection/index')
  }
  const onClickShipment = (event: React.MouseEvent<HTMLInputElement>): void => {
    setOpen(false)
    navigate('/shipment/index')
  }
  const onClickInformation = (event: React.MouseEvent<HTMLInputElement>): void => {
    setOpen(false)
    navigate('/information/index')
  }
  const onClickLogout = (event: React.MouseEvent<HTMLInputElement>): void => {
    logout(() => {})
    setOpen(false)
    navigate('/login')
  }

  return (
    <>
      <AppBar toggleDrawer={toggleDrawer} path={location.pathname} />

      {/* Drawer */}
      {/* 参考：https://mui.com/material-ui/react-drawer/#clipped-under-the-app-bar */}
      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 250,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={onClickTop}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="TOPページ" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onClickSelection}>
                <ListItemIcon>
                  <ShoppingCartOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="セレクト" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onClickProduct}>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="製品検索" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onClickShipment}>
                <ListItemIcon>
                  <CurrencyYenIcon />
                </ListItemIcon>
                <ListItemText primary="売上データ作成" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onClickInformation}>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="アプリ情報" />
              </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem disablePadding>
              <ListItemButton onClick={onClickLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="ログアウト" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      {/* Drawer */}

      <Main open={open}>
        <Toolbar />
        <Outlet />
        <BottomMenu path={location.pathname} />
      </Main>
    </>
  )
}

export default Layout
