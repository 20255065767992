import React from 'react'
import { Box, IconButton, Toolbar, Typography } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'

interface Props {
  toggleDrawer: () => void
  path: string
}

const AppBar: React.FC<Props> = (props) => {
  let title = 'GENRO'
  if (props.path === '/' || props.path.indexOf('/index') === 0) {
    title = 'TOPページ'
  } else if (props.path.indexOf('/selection/') === 0) {
    title = 'セレクト'
  } else if (props.path.indexOf('/product/') === 0) {
    title = '製品検索'
  } else if (props.path.indexOf('/shipment/') === 0) {
    title = '売上データ作成'
  } else if (props.path.indexOf('/information/') === 0) {
    title = 'アプリ情報'
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <MuiAppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={props.toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ display: 'block' }}>
            {title}
          </Typography>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}

export default AppBar
