import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { createClient } from '../util/http-client'
import { AxiosError } from 'axios'
import { User } from '../types'

interface Payload {
  email: string
  password: string
}

const doLogin = async (payload: Payload): Promise<User> => {
  const httpClient = createClient()
  const { data } = await httpClient.post('/login', payload)
  return data
}

export const useLoginMutation = (): UseMutationResult<User, AxiosError, Payload, undefined> => {
  return useMutation(doLogin)
}
