import React from 'react'
import { Box, Typography } from '@mui/material'

const Page404: React.FC = () => {
  return (
    <Box>
      <Typography>ページが見つかりません。</Typography>
    </Box>
  )
}

export default Page404
