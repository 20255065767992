import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import SearchIcon from '@mui/icons-material/Search'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'

interface Props {
  path: string
}

const BottomMenu: React.FC<Props> = (props) => {
  const navigate = useNavigate()

  let value = -1
  if (props.path === '/' || props.path.indexOf('/index') === 0) {
    value = 0
  } else if (props.path.indexOf('/selection/') === 0) {
    value = 1
  } else if (props.path.indexOf('/product/') === 0) {
    value = 2
  }

  return (
    <>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, pb: 2 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            switch (newValue) {
              case 0:
                return navigate(`/index`)
              case 1:
                return navigate('/selection/index')
              case 2:
                if (props.path.indexOf('/product/camera') === 0) {
                  return
                }
                return navigate('/product/camera')
            }
          }}
        >
          <BottomNavigationAction label="TOPページ" icon={<HomeIcon />} />
          <BottomNavigationAction label="セレクト" icon={<ShoppingCartOutlinedIcon />} />
          <BottomNavigationAction label="製品検索" icon={<SearchIcon />} />
        </BottomNavigation>
      </Paper>
    </>
  )
}

export default BottomMenu
