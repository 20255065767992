import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'

interface Props {
  component: React.ReactNode
  redirect: string
}

export const AuthGuard: React.FC<Props> = (props) => {
  const authUser = useAuthContext().user
  if (authUser == null) {
    return <Navigate to={props.redirect} state={{ from: useLocation() }} replace={false} />
  }

  return <>{props.component}</>
}
