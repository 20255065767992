import React, { useCallback, useContext, useMemo, useState } from 'react'
import { AlertColor } from '@mui/material'
import { Snackbar } from '../components/Snackbar'

export interface SnackbarContextType {
  message: string
  severity: AlertColor // 'error' | 'warning' | 'info' | 'success'
  showSnackbar: (message: string, severity: AlertColor) => void
}

export const SnackbarContext = React.createContext<SnackbarContextType>({
  message: '',
  severity: 'error',
  showSnackbar: (_message: string, _severity: AlertColor) => {},
})

interface Props {
  children: React.ReactNode
}

export const SnackbarContextProvider: React.FC<Props> = ({ children }) => {
  const context: SnackbarContextType = useContext(SnackbarContext)
  const [message, setMessage] = useState(context.message)
  const [severity, setSeverity] = useState(context.severity)

  const newContext: SnackbarContextType = useMemo(
    () => ({
      message,
      severity,
      showSnackbar: (message: string, severity: AlertColor) => {
        setMessage(message)
        setSeverity(severity)
      },
    }),
    [message, severity, setMessage, setSeverity],
  )

  const handleClose = useCallback(() => {
    setMessage('')
  }, [setMessage])

  return (
    <SnackbarContext.Provider value={newContext}>
      {children}
      <Snackbar
        open={newContext.message !== ''}
        message={newContext.message}
        severity={newContext.severity}
        onClose={handleClose}
      />
    </SnackbarContext.Provider>
  )
}

export function useSnackbar(): SnackbarContextType {
  return useContext(SnackbarContext)
}
