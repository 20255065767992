import * as React from 'react'
import MuiSnackbar from '@mui/material/Snackbar'
import Alert, { AlertColor } from '@mui/material/Alert'

interface Props {
  open: boolean
  message: string
  severity?: AlertColor
  onClose?: () => void
}

export const Snackbar: React.FC<Props> = ({ open, message, severity = 'info', onClose }) => {
  return (
    <MuiSnackbar
      open={open}
      onClose={onClose}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity={severity} sx={{ width: '100%', mb: 5 }}>
        {message}
      </Alert>
    </MuiSnackbar>
  )
}
